<template>
  <section>
    <div v-if="loading" class="loadingBox">
      <v-progress-circular
        indeterminate
        size="100"
        color="blue"
      ></v-progress-circular>
    </div>
    <div v-if="!loading">
      <LightBox
        v-if="medias.length > 0"
        :media="medias"
        :startAt="index"
        :showLightBox="visible"
        ref="ligthbox"
      ></LightBox>
      <div class="gigBox">
        <div>
          <div class="files" v-if="medias.length > 0">
            <div
              class="post"
              v-for="(n, i) in medias"
              :key="i + 'media'"
              @click="showImages(i)"
            >
              <img :src="n.thumb" alt="" v-if="n.type == 'image'" />

              <video width="320" height="240" controls v-if="n.type == 'video'">
                <source :src="n.thumb" type="video/mp4" />
                <source :src="n.thumb" type="video/ogg" />
                Your browser does not support the video tag.
              </video>

              <v-icon v-if="n.approveStatus"> mdi-check </v-icon>
            </div>
          </div>
        </div>

        <div
          v-if="threads.length > 0"
          class="threadBox"
          ref="threadContent"
          :class="{
            threadBoxHide:
              gigApplied.gigStatus != 'acceptedforposting' ||
              gigApplied.gigStatus == 'applied',
          }"
        >
          <div v-for="(dat, index) in threads" :key="index + 'thread'">
            <div
              class="pb-2 boxThread"
              v-if="
                dat.reason !== 'undefined' ||
                dat.images.length != 0 ||
                dat.videos.length !== 0
              "
            >
              <GigThread
                :thread="dat"
                :threadIndex="index + 1"
                @submittedThread="reFetch"
              />
            </div>
          </div>
        </div>
        <div
          v-if="threads.length == 0"
          class="threadBox noMessageThread d-flex flex-column justify-start align-center"
        >
          <img src="@/assets/images/icon/noMessage.svg" alt="" />
          <h3 class="mt-4">No message yet !</h3>
          <p class="mt-2">Start a conversation and get the things going.</p>
        </div>
      </div>
      <div class="confirmBox" v-if="approveBox">
        <div class="confirmBoxContent">
          <h3>Are you sure you want to send for approval ?</h3>
          <ul v-if="!loadingApproval">
            <li @click="postGigAct">Yes</li>
            <li @click="popBoxToConfirm">No</li>
          </ul>
          <v-progress-circular
            indeterminate
            v-if="loadingApproval"
            class="mt-4"
          ></v-progress-circular>
        </div>
      </div>
      <div
        class="message"
        v-if="
          gigApplied.gigStatus != 'pending' &&
          gigApplied.gigStatus != 'cancelled' &&
          gigApplied.gigStatus != 'applied'
        "
      >
        <v-textarea
          rows="1"
          v-model="reason"
          placeholder="Write a message"
        ></v-textarea>
        <ul class="actBtn">
          <li class="d-flex align-center">
            <input
              type="file"
              multiple
              ref="fileUpload"
              hidden
              @change="onImagePicked"
              accept="image/*,video/*,.zip,.rar,.7zip"
            />
            <v-label @click="onPickImage"
              >Upload Attachment ({{ mediaUrls.length }})</v-label
            >
          </li>
          <li>
            <v-btn class="prmBtn" @click="uploadContent" v-if="!submitLoading"
              >Submit</v-btn
            >
            <v-btn class="prmBtn" v-if="submitLoading" loading></v-btn>
          </li>
        </ul>
        <ul
          v-if="mediaUrls != undefined && mediaUrls.length > 0"
          class="media-list"
        >
          <li v-for="(data, i) in mediaUrls" :key="i" class="imageLoop">
            <span v-if="data.fileType.split(':')[1].split('/')[0] == 'image'">
              <img :src="data.media" alt="" width="50" height="50" />
            </span>
            <span v-if="data.fileType.split(':')[1].split('/')[0] == 'video'">
              <video :src="data.media" alt="" width="50" height="50" />
            </span>
          </li>
        </ul>
      </div>
      <div class="message" v-if="gigApplied.gigStatus == 'applied'">
        You proposal is yet to approve please wait for business to approve
      </div>
    </div>
  </section>
</template>

<script>
import GigThread from "@/components/gig/Thread";
import { mapActions, mapGetters } from "vuex";
import LightBox from "vue-image-lightbox";
export default {
  name: "GigAppliedSingle",
  props: ["id"],
  data: () => ({
    postGig: false,
    postName: undefined,
    description: undefined,
    uploadSuccess: false,
    tag: undefined,
    tags: [],
    index: undefined,
    visible: false,
    contentUpload: false,
    images: undefined,
    medias: [],
    videos: undefined,
    uploadAttachmentStatus: false,
    gig: undefined,
    gigApplied: undefined,
    loading: true,
    threads: [],
    loadingSubmit: false,
    loadingApproval: false,
    approveBox: false,
    submitLoading: false,
    reason: undefined,
    mediaUrls: [],
    documents:[]
  }),
  components: {
    LightBox,
    GigThread,
  },
  computed: {
    ...mapGetters(["allTags", "allGigThread"]),
  },
  methods: {
    ...mapActions([
      "getGigApplied",
      "postToPost",
      "postForApproval",
      "postGigContentThread",
    ]),
    uploadAttachmentAct() {
      this.uploadAttachmentStatus = !this.uploadAttachmentStatus;
    },
    acceptAct() {
      this.apply = !this.apply;
    },
    async uploadAct() {
      this.uploadSuccess = !this.uploadSuccess;
    },
    gigStatusAct(data) {
      this.gigStatus = data;
    },
    async postGigAct() {
      this.loadingApproval = true;
      await this.postForApproval(this.gigApplied._id);
      this.loadingApproval = false;
      this.gigApplied = this.allGigThread.results;
      this.approveBox = false;
      this.reFetch();
    },
    showImages(index) {
      this.index = index;
      this.visible = true;
      this.$refs.ligthbox.showImage(index);
    },
    async uploadContent() {
      this.submitLoading = true;
      this.images = [];
      this.videos = [];
      let fileBundles = this.mediaUrls;

      fileBundles.forEach((ele) => {
        if (ele.fileType.split(":")[1].split("/")[0] == "image") {
          this.images.push(ele.file);
        }

        if (ele.fileType.split(":")[1].split("/")[0] == "video") {
          this.videos.push(ele.file);
        }
        if (ele.fileType.split(":")[1].split("/")[0] != "video" && ele.fileType.split(":")[1].split("/")[0] != "image") {
          this.documents.push(ele.file);
        }
      });
      let formData = new FormData();
      if (this.images != undefined) {
        this.images.forEach((ele) => {
          formData.append("images", ele);
        });
      }

      if (this.videos != undefined) {
        this.videos.forEach((ele) => {
          formData.append("videos", ele);
        });
      }
      if (this.documents != undefined) {
        this.documents.forEach((ele) => {
          formData.append("documents", ele);
        });
      }
      formData.append("id", this.id);
      formData.append("reason", this.reason);
      formData.append("gigStatus", "revision");
      await this.postGigContentThread(formData);
      this.contentUpload = true;

      this.reFetch();
      this.reason = undefined;
      this.submitLoading = false;
      this.mediaUrls = [];
    },
    async reFetch() {
      this.loading = true;
      this.gigApplied = this.allGigThread.results;
      this.gig = this.allGigThread.gig;
      this.threads = this.allGigThread.threads;

      if (this.gigApplied.images.length > 0) {
        this.gigApplied.images.forEach((ele) => {
          this.medias.push({
            type: "image",
            thumb: ele.fileLocation,
            src: ele.fileLocation,
            srcset: ele.fileLocation,
            approveStatus: ele.gigApproveStatus,
          });
        });
      }

      if (this.gigApplied.videos.length > 0) {
        this.gigApplied.videos.forEach((ele) => {
          this.medias.push({
            type: "video",
            thumb: ele.fileLocation,
            src: ele.fileLocation,
            srcset: ele.fileLocation,
            approveStatus: ele.gigApproveStatus,
          });
        });
      }
      this.loading = false;
      this.approveBox = false;
    },
    popBoxToConfirm() {
      this.approveBox = !this.approveBox;
    },
    async postContentOfPost() {
      this.loadingSubmit = true;
      await this.postToPost({
        tags: JSON.stringify(this.allTags),
        id: this.gig._id,
        postName: this.postName,
        description: this.description,
      });
      this.$router.push("/gigApplied");
      this.loadingSubmit = false;
    },
    onPickImage() {
      this.$refs.fileUpload.click();
    },
    onImagePicked(event) {
      const files = event.target.files;
      let filename = files[0].name;
      if (filename.lastIndexOf(".") <= 0) {
        return alert("Please add a valid file !");
      }
      files.forEach((ele) => {
        const fileReader = new FileReader();
        fileReader.addEventListener("load", async () => {
          await this.mediaUrls.push({
            media: fileReader.result,
            file: ele,
            fileType: fileReader.result.split(";")[0],
          });
        });
        fileReader.readAsDataURL(ele);
      });
    },
  },
  async created() {
    await this.getGigApplied(this.id);
    this.gigApplied = this.allGigThread.results;
    this.gig = this.allGigThread.gig;
    this.threads = this.allGigThread.threads;

    if (this.gigApplied.images.length > 0) {
      this.gigApplied.images.forEach((ele) => {
        this.medias.push({
          type: "image",
          thumb: ele.fileLocation,
          src: ele.fileLocation,
          srcset: ele.fileLocation,
          approveStatus: ele.gigApproveStatus,
        });
      });
    }

    if (this.gigApplied.videos.length > 0) {
      this.gigApplied.videos.forEach((ele) => {
        this.medias.push({
          type: "video",
          thumb: ele.fileLocation,
          src: ele.fileLocation,
          srcset: ele.fileLocation,
          approveStatus: ele.gigApproveStatus,
        });
      });
    }

    // container.scrollTop = container.scrollHeight;
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
.buttonList {
  padding-left: 0;
  margin: 25px 0 40px;

  li {
    margin-left: 0;
    .v-btn {
      width: 180px;
      min-height: 50px;
      background: #725ee3;
      color: #fff;
    }
  }
}
.mrg-top-minus {
  margin-top: -35px;
}

.btnWidth {
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 5px;
    width: 250px !important;
    min-height: 50px;
  }
  .waitingBtn {
    background: orange !important;
  }
  .rejectBtn {
    background: red !important;
  }
  .approveBtn {
    background: green !important;
  }
}
h2 {
  font-weight: 700;
  text-transform: capitalize;
  margin-top: 20px;
}
p {
  margin-bottom: 15px !important;
}
header {
  box-shadow: 0 3px 5px #efefef;
  padding: 15px 0 10px;
  margin: 0;
}
.marginrow {
  margin: -15px -15px 0;
}
.tab {
  border: 1px solid #cac6c6;
  display: inline-block;
  padding: 3px 10px;
  font-size: 14px;
  border-radius: 30px;
}
.files {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  .post {
    margin: 3px;
    width: 22%;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: flex-start;
    border: 1px solid #d8d8d8;
    img {
      padding: 10px 0;
      width: 100%;
      object-fit: cover;
    }
    .v-icon {
      left: 10px;
      top: 10px;
      padding: 5px;
      border-radius: 50%;
      position: absolute;
      height: 20px;
      width: 20px;
      font-size: 12px;
      background: rgb(1, 195, 1);
      color: #fff;
    }
  }
}
.active {
  background: green !important;
  color: #fff;
}
.filterButtons {
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  display: flex;
  justify-content: space-between;
  background: #f0f0f0;
  margin: 0 -15px;
  padding: 25px 15px;
  .v-btn {
    width: 32%;
    min-height: 50px;
    background: transparent;
    box-shadow: none;
    border: 1px solid #bcb8b8;
    background: #fff;
    .v-icon {
      margin-right: 10px;
    }
  }
}
.revisionBox {
  border: 1px solid #efefef;
  margin-top: 30px;
  padding: 10px;
  border-right: 0;
  border-left: 0;
  color: #725ee3;
}
.gigBox {
  margin-top: 115px;
  padding: 0 0 20px;
  &:last-of-type {
    border: none;
  }
}
.rejectAct {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-flow: column;
  .v-btn {
    background: transparent;
    box-shadow: none;
    border: 1px solid #d8d8d8;
    text-transform: capitalize;
    margin-top: 20px;
  }
}
.formBox {
  width: 100%;
  margin-top: 20px;
  margin-bottom: -20px;
}
h2 {
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    font-weight: 400;
    font-size: 15px;
    color: #fff;
    padding: 5px 15px;
    border-radius: 30px;
    text-transform: capitalize;
  }
  .revision {
    background: orange;
  }
  .accepted {
    background: green;
  }
  .notaccepteed {
    background: red;
  }
}
.v-btn {
  background: #725ee3 !important;
  border-radius: 30px;
  color: #fff;
  width: 200px;
  min-height: 50px;
}
.loadMore {
  color: #000;
  background: #fff !important;
  min-height: 40px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.loadingBox {
  display: flex;
  align-items: center;
  flex-flow: column;
  justify-content: center;
  padding: 100px 0;
  margin-top: 100px;
}
.confirmBox {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(128, 123, 123, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  .confirmBoxContent {
    height: 200px;
    width: 450px;
    background: #fff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-flow: column;
    justify-content: center;
    ul {
      margin-top: 20px;
      li {
        padding: 8px 20px;
        background: #efefef;
        border-radius: 5px;
        cursor: pointer;
        &:first-of-type {
          background: #725ee3;
          color: #fff;
        }
      }
    }
  }
}
.message {
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 15px;
  border-top: 1px solid #e4d9d9e9;
  background: #fff;
  height: 170px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
  .actBtn {
    padding-left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    li {
      cursor: pointer;
      margin: 0;
      .v-btn {
        border-radius: 0;
      }
    }
  }
}
.threadBox {
  overflow-y: scroll;
}
.threadBoxHide {
  height: 62vh;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
.popBox {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  align-items: center;
  flex-flow: column;
  justify-content: center;
  .popContent {
    width: 80%;
  }
  &.activePopup {
    display: flex;
  }
}
.boxThread {
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.25);
  padding: 10px;
  margin: 0 5px 15px;
  border-radius: 5px;
}
.prmBtn {
  min-height: 40px;
  width: 150px;
  border-radius: 30px !important;
  font-size: 12px !important;
}
.media-list {
  display: flex;
  padding-left: 0;
  margin-top: 20px;
  flex-wrap: wrap;
  li {
    margin-left: 0;
    img,
    video {
      object-fit: cover;
    }
  }
}
</style>