<template>
  <section>
    <div class="headerTop">
      <ul class="menuCursor">
        <li
          @click="gigStatusAct('applied')"
          :class="{ active: gigStatus == 'applied' }"
        >
          Applied
        </li>
        <li
          @click="gigStatusAct('processing')"
          :class="{ active: gigStatus == 'processing' }"
        >
          In Progress
        </li>
        <li
          @click="gigStatusAct('completed')"
          :class="{ active: gigStatus == 'completed' }"
        >
          Completed
        </li>
        <li
          @click="gigStatusAct('rejected')"
          :class="{ active: gigStatus == 'rejected' }"
        >
          Rejected
        </li>
      </ul>
    </div>

    <div v-if="loading" class="loadingBox">
      <v-progress-circular
        indeterminate
        size="100"
        color="blue"
      ></v-progress-circular>
    </div>
    <div v-if="!loading" class="mt-16 pt-5">
      <div
        v-for="(data, index) of gigs"
        :key="index + 'applied'"
        class="gigBox"
      >
        <span
          @click="
            gigDetail(
              data.gig._id,
              data.gigStatus,
              getSubmissionTime(data.submissionDate, true).days >= 0
            )
          "
        >
          <div>
            <h2>
              {{ data.gig.title }}
            </h2>
            <v-row>
              <v-col md="6" cols="6">
                <ul>
                  <li>Posted by:</li>
                  <li>{{ data.gig.businessName }}</li>
                </ul>
                <ul>
                  <li>Posted:</li>
                  <li>{{ getHourTime(data.gig.createdAt) }}</li>
                </ul>
              </v-col>
              <v-col md="6" cols="6">
                <ul>
                  <li v-if="data.gigStatus != 'completed'">Budget:</li>
                  <li v-if="data.gigStatus == 'completed'">
                    Payment Released:
                  </li>
                  <li>
                    Rs. {{ data.gig.offerAmountFrom }}
                    {{
                      data.gig.offerAmountTo && "- Rs." + data.gig.offerAmountTo
                    }}
                  </li>
                </ul>
                <ul v-if="data.submissionDate">
                  <li v-if="data.gigStatus != 'completed'">Time Left:</li>
                  <li
                    v-if="
                      data.gigStatus != 'completed' &&
                      getSubmissionTime(data.submissionDate, true).days >= 0
                    "
                  >
                    {{ getSubmissionTime(data.submissionDate, true).days }}d :
                    {{ getSubmissionTime(data.submissionDate, true).hours }}h :
                    {{ getSubmissionTime(data.submissionDate, true).minutes }}m
                  </li>
                  <li
                    v-if="
                      data.gigStatus != 'completed' &&
                      getSubmissionTime(data.submissionDate, true).days < 0
                    "
                    class="expiredTag"
                  >
                    Expired
                  </li>
                  <li v-if="data.gigStatus == 'completed'">Rating:</li>
                  <li v-if="data.gigStatus == 'completed'">N/A</li>
                </ul>
              </v-col>
            </v-row>
          </div>
        </span>
      </div>
      <div class="mt-5 gigContent" v-if="gigs.length == 0">
        Applied Gig Not Found
      </div>
    </div>
  </section>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Gig",

  data: () => ({
    gigStatus: "applied",
    apply: true,
    postName: undefined,
    description: undefined,
    uploadSuccess: false,
    dropzoneOptions: {
      url: "https://httpbin.org/post",
      thumbnailWidth: 150,
      maxFilesize: 10,
      headers: { "My-Awesome-Header": "header value" },
      addRemoveLinks: true,
      dictDefaultMessage: "Click to upload files",
    },
    search: false,
    gigs: [],
    loading: true,
    timerValue: undefined,
  }),
  methods: {
    ...mapActions(["getGigsApplied"]),
    acceptAct() {
      this.apply = !this.apply;
    },
    uploadAct() {
      this.uploadSuccess = !this.uploadSuccess;
    },
    async gigStatusAct(data) {
      this.loading = true;
      this.gigStatus = data;
      await this.getGigsApplied({
        limit: 10,
        page: 1,
        gigStatus: this.gigStatus,
      });
      this.gigs = this.allGigs.results;
      this.loading = false;
    },
    searchToggle() {
      this.search = !this.search;
      this.loading = false;
    },
    getHourTime(date) {
      const now = moment(date);
      const expiration = moment(new Date());
      const diff = expiration.diff(now);
      const diffDuration = moment.duration(diff);
      if (diffDuration.days() != 0) {
        return diffDuration.days() + " day ago";
      }
      if (diffDuration.hours() != 0) {
        return diffDuration.hours() + " hour ago";
      }
      if (diffDuration.minutes != 0) {
        return diffDuration.minutes() + " minute ago";
      }
      return {
        days: diffDuration.days(),
        hours: diffDuration.hours(),
        minutes: diffDuration.minutes(),
      };
    },
    getSubmissionTime(date, reverse) {
      let now;
      let expiration;
      if (reverse) {
        now = moment(new Date());
        expiration = moment(date);
      } else {
        expiration = moment(new Date());
        now = moment(date);
      }
      const diff = expiration.diff(now);
      const diffDuration = moment.duration(diff);
      if (reverse) {
        return {
          days: diffDuration.days(),
          hours: diffDuration.hours(),
          minutes: diffDuration.minutes(),
          seconds: diffDuration.seconds(),
        };
      } else {
        if (diffDuration.days() != 0) {
          return diffDuration.days() + " day";
        }
        if (diffDuration.hours() != 0) {
          return diffDuration.hours() + " hour";
        }
        if (diffDuration.minutes != 0) {
          return diffDuration.minutes() + " minute";
        }
      }
    },
    gigDetail(link, gigStatus, dateStatus) {
      console.log(gigStatus, dateStatus);
      // if (gigStatus == "completed" || dateStatus) {
      this.$router.push(`/gig/${link}?page=details`);
      // }
    },
  },
  computed: {
    ...mapGetters(["allGigs"]),
  },
  async created() {
    await this.getGigsApplied({
      limit: 10,
      page: 1,
      gigStatus: this.gigStatus,
    });
    this.gigs = this.allGigs.results;

    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
.buttonList {
  padding-left: 0;
  margin: 25px 0 40px;

  li {
    margin-left: 0;
    .v-btn {
      width: 180px;
      min-height: 50px;
      background: #725ee3;
      color: #fff;
    }
  }
}
.btnWidth {
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 5px;
    width: 250px !important;
    min-height: 50px;
  }
  .waitingBtn {
    background: orange !important;
  }
  .rejectBtn {
    background: red !important;
  }
  .approveBtn {
    background: green !important;
  }
}
h2 {
  font-weight: 700;
  margin-top: 20px;
  a {
    color: #000;
  }
}
p {
  margin-bottom: 15px !important;
}
header {
  box-shadow: 0 3px 5px #efefef;
  padding: 15px 0 10px;
  margin: 0;
  img {
    width: 160px;
    margin-top: 10px;
  }
}
.marginrow {
  margin: -15px -15px 0;
}
.tab {
  border: 1px solid #cac6c6;
  display: inline-block;
  padding: 3px 10px;
  font-size: 14px;
  border-radius: 30px;
}
.files {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  .post {
    margin: 3px;
    width: 23%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid #d8d8d8;
    img {
      padding: 10px 0;
      width: 100%;
      object-fit: cover;
    }
  }
}

.filterButtons {
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  display: flex;
  justify-content: space-between;
  background: #f0f0f0;
  margin: 0 -15px;
  padding: 25px 15px;
  .v-btn {
    width: 32%;
    min-height: 50px;
    background: transparent;
    box-shadow: none;
    border: 1px solid #bcb8b8;
    background: #fff;
    .v-icon {
      margin-right: 10px;
    }
  }
}
.gigBox {
  padding: 0 15px 20px;
  background: #ffffff;
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.25);
  padding: 15px 12px;
  margin: 0 0 15px;
  border-radius: 8px;
  &:last-of-type {
    margin-bottom: 0;
  }
  h2 {
    text-transform: capitalize;
    margin-top: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 16px;
    color: #000;
  }

  ul {
    padding-left: 0;
    margin-bottom: 0;
    display: flex;
    flex-flow: column;
    margin-bottom: 10px;
    li {
      font-size: 14px;
      margin: 0;
      color: #444444;
      &:last-of-type {
        font-weight: 500;
        color: #000;
      }
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
.rejectAct {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-flow: column;
  .v-btn {
    background: transparent;
    box-shadow: none;
    border: 1px solid #d8d8d8;
    text-transform: capitalize;
    margin-top: 20px;
  }
}
.formBox {
  width: 100%;
  margin-top: 20px;
  margin-bottom: -20px;
}
header {
  box-shadow: 0 3px 5px #efefef;
  padding: 10px 20px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 -15px 0;


}

.headerTop {
  background: #5b3de8;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  right: 0;
  h2 {
    margin: 0;
    margin-bottom: 25px;
    text-transform: capitalize;
    color: #fff;
  }
  ul {
    padding-left: 0;
    margin: 0 -20px;
    li {
      margin-left: 0;
      color: #fff;
      border-bottom: 2px solid transparent;
      padding: 20px 10px;
      width: 25%;
      margin: 0;
      text-align: center;
      font-weight: 400;
    }
    .active {
      border-bottom: 2px solid #fff;
    }
  }
}

.headericons {
  .v-icon {
    margin-left: 25px;
  }
}
.loadingBox {
  display: flex;
  align-items: center;
  flex-flow: column;
  justify-content: center;
  padding: 100px 0;
}
a {
  color: #000;
}
.menuCursor {
  li {
    cursor: pointer;
    font-size: 12px;
  }
}
.gigContent {
  font-size: 12px;
}
.expiredTag {
  background: red;
  width: 80px;
  text-align: center;
  border-radius: 30px;
  color: #fff !important;
}
</style>