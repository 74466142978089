<template>
  <section class="gigPaymentSpaceTop">
    <h3>Payment System</h3>
    <ul class="paymentlist">
      <li>
        <dt>Payment Release:</dt>
        <dd>Rs. {{ gig.gigAppliedDetail.payment || gig.offerAmountFrom }}</dd>
      </li>
      <li>
        <dt>In Progress:</dt>
        <dd>Rs. 0</dd>
      </li>
      <li>
        <dt>Released:</dt>
        <dd>Rs. 0</dd>
      </li>
    </ul>
    <!-- <h3 class="secondBox">Payment Request</h3>
    <ul class="paymentlist">
      <li>
        <dt>Date</dt>
        <dd>12 May 2022</dd>
      </li>
      <li>
        <dt>Description</dt>
        <dd>I am done with the changes.</dd>
      </li>
    </ul> -->
    <div class="mt-5">
      <h3 class="mb-5">Post your gig</h3>

      <div class="formBox postGig">
        <v-text-field
          v-model="postName"
          outlined
          placeholder="Post name"
        ></v-text-field>
        <div class="applyGig">
          <v-textarea
            v-model="description"
            outlined
            placeholder="Description"
          ></v-textarea>
        </div>
        <div class="mrg-top-minus">
          <AddTag />
        </div>
        <strong v-if="gig.numberofimage > 0">{{ gig.numberofimage }} Images Required</strong>
        <strong v-if="gig.numberofvideo > 0">{{ gig.numberofvideo }} Video Required</strong>
        <div class="mrg-top-minus">
          <input
            type="file"
            multiple
            ref="fileUpload"
            hidden
            @change="onImagePicked"
            accept="image/*,video/*"
          />
          <v-label class="actionToUpload" @click="onPickImage"
            >Upload Attachment ({{ mediaUrls.length }})</v-label
          >
        </div>
      </div>
      <ul
        v-if="mediaUrls != undefined && mediaUrls.length > 0"
        class="media-list"
      >
        <li v-for="(data, i) in mediaUrls" :key="i" class="imageLoop">
          <span v-if="data.fileType.split(':')[1].split('/')[0] == 'image'">
            <img :src="data.media" alt="" width="50" height="50" />
          </span>
          <span v-if="data.fileType.split(':')[1].split('/')[0] == 'video'">
            <video :src="data.media" alt="" width="50" height="50" />
          </span>
        </li>
      </ul>

      <div class="mt-5">
        <v-btn
          v-if="!loadingSubmit"
          class="primaryBtn"
          @click="postContentOfPost"
          >Submit</v-btn
        >
        <v-btn v-if="loadingSubmit" loading></v-btn>
      </div>
    </div>
  </section>
</template>

<script>
import AddTag from "@/components/user/AddTag";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "GigPayment",
  data: () => ({
    postName: undefined,
    description: undefined,
    loadingSubmit: false,
    mediaUrls: [],
    gig: undefined,
    images: [],
    videos: [],
  }),
  components: {
    AddTag,
  },
  methods: {
    ...mapActions(["getGig", "postToPost"]),
    async postContentOfPost() {
      this.loadingSubmit = true;
      this.images = [];
      this.videos = [];
      let fileBundles = this.mediaUrls;

      fileBundles.forEach((ele) => {
        if (ele.fileType.split(":")[1].split("/")[0] == "image") {
          this.images.push(ele.file);
        }

        if (ele.fileType.split(":")[1].split("/")[0] == "video") {
          this.videos.push(ele.file);
        }
      });
      let formData = new FormData();
      if (this.images != undefined) {
        this.images.forEach((ele) => {
          formData.append("images", ele);
        });
      }

      if (this.videos != undefined) {
        this.videos.forEach((ele) => {
          formData.append("videos", ele);
        });
      }
      formData.append("tags", JSON.stringify(this.allTags));
      formData.append("id", this.gig._id);
      formData.append("postName", this.postName);
      formData.append("description", this.description);
      await this.postToPost(formData);
      this.$router.push("/gigApplied");
      this.loadingSubmit = false;
    },
    onPickImage() {
      this.$refs.fileUpload.click();
    },
    onImagePicked(event) {
      const files = event.target.files;
      let filename = files[0].name;
      if (filename.lastIndexOf(".") <= 0) {
        return alert("Please add a valid file !");
      }
      files.forEach((ele) => {
        const fileReader = new FileReader();
        fileReader.addEventListener("load", async () => {
          await this.mediaUrls.push({
            media: fileReader.result,
            file: ele,
            fileType: fileReader.result.split(";")[0],
          });
        });
        fileReader.readAsDataURL(ele);
      });
    },
  },
  computed: {
    ...mapGetters(["allGig", "allTags"]),
  },
  created() {
    this.gig = this.allGig.results;
  },
};
</script>

<style lang="scss" scoped>
.paymentlist {
  padding-left: 0;
  border-top: 1px solid rgb(202, 189, 189);
  width: 106%;
  margin: 10px -15px 0;
  padding: 0 15px;
  li {
    padding: 10px 0;
    margin: 0;
    display: flex;
    dt {
      font-weight: 500;
    }
    dt,
    dd {
      width: 45%;
    }
    &:first-of-type {
      margin-top: 10px;
    }
  }
}
.secondBox {
  border-top: 1px solid rgb(202, 189, 189);
  width: 106%;
  margin: 20px -15px 0;
  padding: 10px 15px 0;
}
.media-list {
  display: flex;
  padding-left: 0;
  margin-top: 20px;
  flex-wrap: wrap;
  li {
    margin-left: 0;
    img,
    video {
      object-fit: cover;
    }
  }
}
.primaryBtn {
  background: rgb(45, 32, 221);
  color: #fff;
  margin-right: 10px;
  border-radius: 30px;
  width: 150px;
}
.gigPaymentSpaceTop {
  margin-top: 110px;
}
.actionToUpload {
  color: #2d20dd;
  cursor: pointer !important;
  min-height: 50px !important;
}
</style>