var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"headerTop"},[_c('ul',{staticClass:"menuCursor"},[_c('li',{class:{ active: _vm.gigStatus == 'applied' },on:{"click":function($event){return _vm.gigStatusAct('applied')}}},[_vm._v(" Applied ")]),_c('li',{class:{ active: _vm.gigStatus == 'processing' },on:{"click":function($event){return _vm.gigStatusAct('processing')}}},[_vm._v(" In Progress ")]),_c('li',{class:{ active: _vm.gigStatus == 'completed' },on:{"click":function($event){return _vm.gigStatusAct('completed')}}},[_vm._v(" Completed ")]),_c('li',{class:{ active: _vm.gigStatus == 'rejected' },on:{"click":function($event){return _vm.gigStatusAct('rejected')}}},[_vm._v(" Rejected ")])])]),(_vm.loading)?_c('div',{staticClass:"loadingBox"},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"100","color":"blue"}})],1):_vm._e(),(!_vm.loading)?_c('div',{staticClass:"mt-16 pt-5"},[_vm._l((_vm.gigs),function(data,index){return _c('div',{key:index + 'applied',staticClass:"gigBox"},[_c('span',{on:{"click":function($event){_vm.gigDetail(
            data.gig._id,
            data.gigStatus,
            _vm.getSubmissionTime(data.submissionDate, true).days >= 0
          )}}},[_c('div',[_c('h2',[_vm._v(" "+_vm._s(data.gig.title)+" ")]),_c('v-row',[_c('v-col',{attrs:{"md":"6","cols":"6"}},[_c('ul',[_c('li',[_vm._v("Posted by:")]),_c('li',[_vm._v(_vm._s(data.gig.businessName))])]),_c('ul',[_c('li',[_vm._v("Posted:")]),_c('li',[_vm._v(_vm._s(_vm.getHourTime(data.gig.createdAt)))])])]),_c('v-col',{attrs:{"md":"6","cols":"6"}},[_c('ul',[(data.gigStatus != 'completed')?_c('li',[_vm._v("Budget:")]):_vm._e(),(data.gigStatus == 'completed')?_c('li',[_vm._v(" Payment Released: ")]):_vm._e(),_c('li',[_vm._v(" Rs. "+_vm._s(data.gig.offerAmountFrom)+" "+_vm._s(data.gig.offerAmountTo && "- Rs." + data.gig.offerAmountTo)+" ")])]),(data.submissionDate)?_c('ul',[(data.gigStatus != 'completed')?_c('li',[_vm._v("Time Left:")]):_vm._e(),(
                    data.gigStatus != 'completed' &&
                    _vm.getSubmissionTime(data.submissionDate, true).days >= 0
                  )?_c('li',[_vm._v(" "+_vm._s(_vm.getSubmissionTime(data.submissionDate, true).days)+"d : "+_vm._s(_vm.getSubmissionTime(data.submissionDate, true).hours)+"h : "+_vm._s(_vm.getSubmissionTime(data.submissionDate, true).minutes)+"m ")]):_vm._e(),(
                    data.gigStatus != 'completed' &&
                    _vm.getSubmissionTime(data.submissionDate, true).days < 0
                  )?_c('li',{staticClass:"expiredTag"},[_vm._v(" Expired ")]):_vm._e(),(data.gigStatus == 'completed')?_c('li',[_vm._v("Rating:")]):_vm._e(),(data.gigStatus == 'completed')?_c('li',[_vm._v("N/A")]):_vm._e()]):_vm._e()])],1)],1)])])}),(_vm.gigs.length == 0)?_c('div',{staticClass:"mt-5 gigContent"},[_vm._v(" Applied Gig Not Found ")]):_vm._e()],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }