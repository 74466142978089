<template>
  <section class="gigPaymentSpaceTop">
    <div>
      <h3>Rate Business</h3>
      <v-rating
        value="0"
        v-model="businessRating"
        size="50"
        color="orange"
        background-color="#cccccc"
      ></v-rating>
      <v-textarea
        class="borderedTextArea"
        v-model="businessReview"
        outlined
        placeholder="Business Review"
      ></v-textarea>
    </div>
    <div class="mt-10">
      <h3>Rate Gig</h3>
      <v-rating
        value="0"
        size="50"
        v-model="gigRating"
        color="orange"
        background-color="#cccccc"
      ></v-rating>
      <v-textarea
        class="borderedTextArea"
        v-model="gigReview"
        outlined
        placeholder="Gig Review"
      ></v-textarea>
    </div>
    <div>
      <v-btn
        class="primaryBtn"
        v-if="!loading"
        @click="submit"
        :disabled="
          gigReview.length == 0 ||
          businessReview.length == 0 ||
          businessRating == 0 ||
          gigRating == 0
        "
        >Submit</v-btn
      >
      <v-btn class="primaryBtn" v-if="loading" @click="submit" loading></v-btn>
    </div>
    <v-snackbar v-model="snackbar" :timeout="6000">
      {{ snackbarText }}
    </v-snackbar>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "GigRate",
  props: ["id", "businessHandle", "rating"],
  data: () => ({
    businessRating: 0,
    gigRating: 0,
    loading: false,
    businessReview: "",
    gigReview: "",
    snackbar: false,
    snackbarText: "",
  }),
  methods: {
    ...mapActions(["postBusinessRating", "postGigRating"]),
    async addBusinessRating() {
      let data = {
        connectedTo: this.businessHandle,
        rating: this.businessRating,
        description: this.businessReview,
      };
      await this.postBusinessRating(data);
    },
    async addGigRating() {
      let data = {
        connectedTo: this.id,
        rating: this.gigRating,
        description: this.gigReview,
      };
      await this.postGigRating(data);
    },
    async submit() {
      this.loading = true;
      if (this.businessRating != 0 && this.gigRating != 0) {
        await this.addBusinessRating();
        await this.addGigRating();
      }
      if (this.allGigRating.status == 200) {
        this.snackbar = true;
        this.snackbarText = "Rating Submitted";
      } else {
        this.snackbar = true;
        this.snackbarText = "You have already rated this gig";
      }

      this.loading = false;
    },
  },
  computed: {
    ...mapGetters(["allGigRating"]),
  },
  created(){
    this.businessRating = this.rating.business.rating;
    this.gigRating = this.rating.gig.rating;
    this.businessReview = this.rating.business.description != undefined ? this.rating.business.description : "";
    this.gigReview = this.rating.gig.description != undefined ? this.rating.gig.description : "";
  }
};
</script>

<style lang="scss" scoped>
h3 {
  margin-bottom: 10px;
}
.primaryBtn {
  background: rgb(45, 32, 221);
  color: #fff;
  margin-right: 10px;
  border-radius: 30px;
  width: 150px;
  margin-top: 20px;
  min-height: 40px;
}
.gigPaymentSpaceTop {
  margin-top: 150px;
}
</style>