var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"gigBoxSingle"},[(_vm.loading)?_c('div',{staticClass:"loadingBox"},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"100","color":"blue"}})],1):_vm._e(),(!_vm.loading)?_c('div',[_c('div',{staticClass:"headerTop"},[_c('h2',{staticClass:"d-flex justify-space-between"},[_vm._v(" "+_vm._s(_vm.gig.title)+" "),(
            _vm.gig.gigAppliedDetail &&
            _vm.gig.gigAppliedDetail.gigStatus != 'appliedforapproval' &&
            _vm.gig.gigAppliedDetail.gigStatus != 'cancelled' &&
            _vm.gig.gigAppliedDetail.gigStatus != 'completed' &&
            _vm.dateDiff >= 1
          )?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"white"},on:{"click":_vm.popAction}},[_vm._v(" mdi-cog ")]):_vm._e()],1),_c('ul',{staticClass:"menuLink"},[_c('li',{class:{ active: _vm.menuBox == 'details' },on:{"click":function($event){return _vm.changeMenu('details')}}},[_vm._v(" Details ")]),(_vm.gig.gigAppliedDetail != null && _vm.dateDiff > 0)?_c('li',{class:{ active: _vm.menuBox == 'messages' },on:{"click":function($event){return _vm.changeMenu('messages')}}},[_vm._v(" Messages ")]):_vm._e(),(
            _vm.gig.gigAppliedDetail != null &&
            (_vm.gig.gigAppliedDetail.gigStatus == 'acceptedforposting' ||
              _vm.gig.gigAppliedDetail.gigStatus == 'requestforposting')
          )?_c('li',{class:{ active: _vm.menuBox == 'payments' },on:{"click":function($event){return _vm.changeMenu('payments')}}},[_vm._v(" Payment ")]):_vm._e(),(
            _vm.gig.gigAppliedDetail != null &&
            _vm.gig.gigAppliedDetail.gigStatus == 'completed'
          )?_c('li',{class:{ active: _vm.menuBox == 'rate' },on:{"click":function($event){return _vm.changeMenu('rate')}}},[_vm._v(" Review ")]):_vm._e()])]),(_vm.menuBox == 'details' && !_vm.loading)?_c('GigDetail'):_vm._e(),(_vm.menuBox == 'messages' && !_vm.loading)?_c('GigAppliedSingle',{attrs:{"id":_vm.gig.gigAppliedDetail._id}}):_vm._e(),(_vm.menuBox == 'payments' && _vm.gig.gigAppliedDetail && !_vm.loading)?_c('GigPayment',{attrs:{"id":_vm.gig.gigAppliedDetail._id}}):_vm._e(),(_vm.menuBox == 'rate' && _vm.gig.gigAppliedDetail && !_vm.loading)?_c('GigRate',{attrs:{"id":_vm.gig._id,"businessHandle":_vm.gig.business.userHandle,"rating":_vm.gig.rating}}):_vm._e(),(_vm.popstatus)?_c('div',{staticClass:"popBox"},[_c('div',{staticClass:"mask",on:{"click":_vm.modalClose}}),_c('ul',[_c('li',[(!_vm.loadingApproval)?_c('v-btn',{staticClass:"btnTran",on:{"click":_vm.postGigAct}},[_vm._v("Submit for approval")]):_vm._e(),(_vm.loadingApproval)?_c('v-btn',{staticClass:"btnTran",attrs:{"loading":""}}):_vm._e()],1),_c('li',[_c('v-btn',{staticClass:"btnTran",on:{"click":_vm.popCancelAct}},[_vm._v("Cancel Gig")])],1)])]):_vm._e(),(_vm.popcancelStatus)?_c('div',{staticClass:"popBox"},[_c('ul',[_c('li',{staticClass:"d-flex flex-column"},[_vm._v(" Are you sure you want to cancel gig ? "),_c('v-btn',{staticClass:"btnTran",on:{"click":_vm.cancelGig}},[_vm._v("Yes")]),_c('v-btn',{staticClass:"btnTran",on:{"click":_vm.popCancelAct}},[_vm._v(" No ")])],1)])]):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }