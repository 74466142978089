var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[(_vm.loading)?_c('div',{staticClass:"loadingBox"},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"100","color":"blue"}})],1):_vm._e(),(!_vm.loading)?_c('div',[(_vm.medias.length > 0)?_c('LightBox',{ref:"ligthbox",attrs:{"media":_vm.medias,"startAt":_vm.index,"showLightBox":_vm.visible}}):_vm._e(),_c('div',{staticClass:"gigBox"},[_c('div',[(_vm.medias.length > 0)?_c('div',{staticClass:"files"},_vm._l((_vm.medias),function(n,i){return _c('div',{key:i + 'media',staticClass:"post",on:{"click":function($event){return _vm.showImages(i)}}},[(n.type == 'image')?_c('img',{attrs:{"src":n.thumb,"alt":""}}):_vm._e(),(n.type == 'video')?_c('video',{attrs:{"width":"320","height":"240","controls":""}},[_c('source',{attrs:{"src":n.thumb,"type":"video/mp4"}}),_c('source',{attrs:{"src":n.thumb,"type":"video/ogg"}}),_vm._v(" Your browser does not support the video tag. ")]):_vm._e(),(n.approveStatus)?_c('v-icon',[_vm._v(" mdi-check ")]):_vm._e()],1)}),0):_vm._e()]),(_vm.threads.length > 0)?_c('div',{ref:"threadContent",staticClass:"threadBox",class:{
          threadBoxHide:
            _vm.gigApplied.gigStatus != 'acceptedforposting' ||
            _vm.gigApplied.gigStatus == 'applied',
        }},_vm._l((_vm.threads),function(dat,index){return _c('div',{key:index + 'thread'},[(
              dat.reason !== 'undefined' ||
              dat.images.length != 0 ||
              dat.videos.length !== 0
            )?_c('div',{staticClass:"pb-2 boxThread"},[_c('GigThread',{attrs:{"thread":dat,"threadIndex":index + 1},on:{"submittedThread":_vm.reFetch}})],1):_vm._e()])}),0):_vm._e(),(_vm.threads.length == 0)?_c('div',{staticClass:"threadBox noMessageThread d-flex flex-column justify-start align-center"},[_c('img',{attrs:{"src":require("@/assets/images/icon/noMessage.svg"),"alt":""}}),_c('h3',{staticClass:"mt-4"},[_vm._v("No message yet !")]),_c('p',{staticClass:"mt-2"},[_vm._v("Start a conversation and get the things going.")])]):_vm._e()]),(_vm.approveBox)?_c('div',{staticClass:"confirmBox"},[_c('div',{staticClass:"confirmBoxContent"},[_c('h3',[_vm._v("Are you sure you want to send for approval ?")]),(!_vm.loadingApproval)?_c('ul',[_c('li',{on:{"click":_vm.postGigAct}},[_vm._v("Yes")]),_c('li',{on:{"click":_vm.popBoxToConfirm}},[_vm._v("No")])]):_vm._e(),(_vm.loadingApproval)?_c('v-progress-circular',{staticClass:"mt-4",attrs:{"indeterminate":""}}):_vm._e()],1)]):_vm._e(),(
        _vm.gigApplied.gigStatus != 'pending' &&
        _vm.gigApplied.gigStatus != 'cancelled' &&
        _vm.gigApplied.gigStatus != 'applied'
      )?_c('div',{staticClass:"message"},[_c('v-textarea',{attrs:{"rows":"1","placeholder":"Write a message"},model:{value:(_vm.reason),callback:function ($$v) {_vm.reason=$$v},expression:"reason"}}),_c('ul',{staticClass:"actBtn"},[_c('li',{staticClass:"d-flex align-center"},[_c('input',{ref:"fileUpload",attrs:{"type":"file","multiple":"","hidden":"","accept":"image/*,video/*,.zip,.rar,.7zip"},on:{"change":_vm.onImagePicked}}),_c('v-label',{on:{"click":_vm.onPickImage}},[_vm._v("Upload Attachment ("+_vm._s(_vm.mediaUrls.length)+")")])],1),_c('li',[(!_vm.submitLoading)?_c('v-btn',{staticClass:"prmBtn",on:{"click":_vm.uploadContent}},[_vm._v("Submit")]):_vm._e(),(_vm.submitLoading)?_c('v-btn',{staticClass:"prmBtn",attrs:{"loading":""}}):_vm._e()],1)]),(_vm.mediaUrls != undefined && _vm.mediaUrls.length > 0)?_c('ul',{staticClass:"media-list"},_vm._l((_vm.mediaUrls),function(data,i){return _c('li',{key:i,staticClass:"imageLoop"},[(data.fileType.split(':')[1].split('/')[0] == 'image')?_c('span',[_c('img',{attrs:{"src":data.media,"alt":"","width":"50","height":"50"}})]):_vm._e(),(data.fileType.split(':')[1].split('/')[0] == 'video')?_c('span',[_c('video',{attrs:{"src":data.media,"alt":"","width":"50","height":"50"}})]):_vm._e()])}),0):_vm._e()],1):_vm._e(),(_vm.gigApplied.gigStatus == 'applied')?_c('div',{staticClass:"message"},[_vm._v(" You proposal is yet to approve please wait for business to approve ")]):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }