<template>
  <section class="gigBoxSingle">
    <div v-if="loading" class="loadingBox">
      <v-progress-circular
        indeterminate
        size="100"
        color="blue"
      ></v-progress-circular>
    </div>
    <div v-if="!loading">
      <div class="headerTop">
        <h2 class="d-flex justify-space-between">
          {{ gig.title }}
          <v-icon
            color="white"
            class="mr-2"
            @click="popAction"
            v-if="
              gig.gigAppliedDetail &&
              gig.gigAppliedDetail.gigStatus != 'appliedforapproval' &&
              gig.gigAppliedDetail.gigStatus != 'cancelled' &&
              gig.gigAppliedDetail.gigStatus != 'completed' &&
              dateDiff >= 1
            "
          >
            mdi-cog
          </v-icon>
        </h2>
        <ul class="menuLink">
          <li
            :class="{ active: menuBox == 'details' }"
            @click="changeMenu('details')"
          >
            Details
          </li>
          <li
            v-if="gig.gigAppliedDetail != null && dateDiff > 0"
            :class="{ active: menuBox == 'messages' }"
            @click="changeMenu('messages')"
          >
            Messages
          </li>

          <li
            v-if="
              gig.gigAppliedDetail != null &&
              (gig.gigAppliedDetail.gigStatus == 'acceptedforposting' ||
                gig.gigAppliedDetail.gigStatus == 'requestforposting')
            "
            :class="{ active: menuBox == 'payments' }"
            @click="changeMenu('payments')"
          >
            Payment
          </li>
          <li
            v-if="
              gig.gigAppliedDetail != null &&
              gig.gigAppliedDetail.gigStatus == 'completed'
            "
            :class="{ active: menuBox == 'rate' }"
            @click="changeMenu('rate')"
          >
            Review
          </li>
        </ul>
      </div>

      <GigDetail v-if="menuBox == 'details' && !loading" />

      <GigAppliedSingle
        v-if="menuBox == 'messages' && !loading"
        :id="gig.gigAppliedDetail._id"
      />
      <GigPayment
        v-if="menuBox == 'payments' && gig.gigAppliedDetail && !loading"
        :id="gig.gigAppliedDetail._id"
      />
      <GigRate
        v-if="menuBox == 'rate' && gig.gigAppliedDetail && !loading"
        :id="gig._id"
        :businessHandle="gig.business.userHandle"
        :rating="gig.rating"
      />
      <div class="popBox" v-if="popstatus">
        <div @click="modalClose" class="mask"></div>
        <ul>
          <li>
            <v-btn class="btnTran" @click="postGigAct" v-if="!loadingApproval"
              >Submit for approval</v-btn
            ><v-btn v-if="loadingApproval" class="btnTran" loading></v-btn>
          </li>
          <li>
            <v-btn @click="popCancelAct" class="btnTran">Cancel Gig</v-btn>
          </li>
        </ul>
      </div>
      <div class="popBox" v-if="popcancelStatus">
        <ul>
          <li class="d-flex flex-column">
            Are you sure you want to cancel gig ?
            <v-btn class="btnTran" @click="cancelGig">Yes</v-btn
            ><v-btn class="btnTran" @click="popCancelAct"> No </v-btn>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import GigDetail from "@/components/gig/GigDetail";
import GigPayment from "@/components/gig/GigPayment";
import GigAppliedSingle from "@/components/gig/GigAppliedSingle";
import GigRate from "@/components/gig/GigRate";
import moment from "moment";
export default {
  name: "Gig",
  data: () => ({
    loading: true,
    popstatus: false,
    menuBox: "details",
    loadingApproval: false,
    popcancelStatus: false,
    dateDiff: undefined,
  }),
  components: {
    GigDetail,
    GigAppliedSingle,
    GigPayment,
    GigRate,
  },
  methods: {
    ...mapActions(["getGig", "postForApproval", "postGigContentThread"]),
    changeMenu(data) {
      this.$router.push(`?page=${data}`);
    },
    modalClose() {
      this.popstatus = false;
    },
    popCancelAct() {
      this.popcancelStatus = !this.popcancelStatus;
      this.closePopAction();
    },
    async postGigAct() {
      this.loadingApproval = true;
      await this.postForApproval(this.gig.gigAppliedDetail._id);
      this.loadingApproval = false;
      this.closePopAction();
    },
    async cancelGig() {
      this.loading = true;
      let formData = new FormData();
      formData.append("id", this.gig.gigAppliedDetail._id);
      formData.append("reason", "Cancelled");
      formData.append("gigStatus", "cancelled");
      await this.postGigContentThread(formData);
      this.contentUpload = true;

      await this.getGig(this.$route.params.id);
      this.gig = this.allGig.results;
      this.closePopAction();
      this.loading = false;
    },
    popAction() {
      this.popstatus = true;
    },
    closePopAction() {
      this.popstatus = false;
    },
  },
  computed: {
    ...mapGetters(["allGig"]),
  },
  async created() {
    this.menuBox = this.$route.query.page;
    await this.getGig(this.$route.params.id);
    this.gig = this.allGig.results;
    this.loading = false;
    if (this.gig.gigAppliedDetail && this.gig.gigAppliedDetail.submissionDate) {
      var a = moment(new Date(this.gig.gigAppliedDetail.submissionDate));
      var b = moment(new Date());
      this.dateDiff = a.diff(b, "days"); // 1
    }
  },
};
</script>

<style lang="scss" scoped>
.gigBoxSingle {
  padding-bottom: 30px;
}
.titleBox {
  border: 1px solid #dcdcdc;
  border-left: none;
  border-right: none;
  width: 106%;
  margin: 0 -15px 10px;
  padding: 15px 20px;
  small {
    font-weight: 300;
    font-size: 12px;
  }
}
.detailBox {
  margin-left: 0;
  padding-left: 0;
  margin-top: 20px;
  img {
    width: 50px;
    height: 50px;
    border: 1px solid #dcdcdc;
    object-fit: cover;
    margin-right: 15px;
    border-radius: 3px;
  }
  li {
    display: flex;
    align-items: center;
    margin-left: 0;
    dl {
      width: 100%;
      display: flex;
      dt,
      dd {
        width: 50%;
      }
      dd {
        font-weight: 500;
      }
    }
    strong {
      font-size: 16px;
    }
  }
}

.buttonList {
  padding-left: 0;
  margin: 0 0 40px;
  width: 100%;
  li {
    margin: 0 !important;
    width: 100%;
    .v-btn {
      width: 100%;
      min-height: 50px;
      background: #725ee3;
      color: #fff;
    }
  }
}

h2 {
  font-weight: 500;
  margin-top: 20px;
  font-size: 20px;
}
p {
  margin-bottom: 15px !important;
}
header {
  box-shadow: 0 3px 5px #efefef;
  padding: 15px 0 10px;
  margin: 0;
  img {
    width: 160px;
  }
}
.marginrow {
  margin: -15px -15px 0;
}
.tab {
  padding: 5px 15px;
  font-size: 12px;
  border-radius: 30px;
  margin-top: 10px;
  margin-bottom: 20px;
  display: inline-block;
  background: #bcbcbc40;
  color: #444444;
}

.priceExpBox {
  display: flex;
  justify-content: space-between;
  li {
    p {
      &:first-of-type {
        font-weight: 500;
      }
    }
  }
}
.infoProposal {
  width: 100%;
  li {
    display: flex;
    padding: 10px 0;
    justify-content: space-between;
  }
}
.loadingBox {
  display: flex;
  align-items: center;
  flex-flow: column;
  justify-content: center;
  padding: 100px 0;
}
.headerTop {
  position: fixed;
  background: #5b3de8;
  width: 100%;
  padding: 15px 25px 0;
  z-index: 9999;
  top: 0;
  left: 0;
  h2 {
    margin: 0;
    margin-bottom: 10px;
    text-transform: capitalize;
    color: #fff;
  }
  ul {
    padding-left: 0;
    margin: 0 -20px;
    li {
      margin-left: 0;
      color: #fff;
      border-bottom: 2px solid transparent;
      padding: 10px 10px;
      width: 25%;
      margin: 0;
      text-align: center;
      font-weight: 400;
    }
    .active {
      border-bottom: 2px solid #fff;
    }
  }
}
.popBox {
  position: fixed;
  z-index: 99;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  ul {
    display: flex;
    align-items: center;
    flex-flow: column;
    justify-content: center;
    background: #fff;
    border: 1px solid rgb(206, 204, 204);
    border-radius: 10px;
    padding-left: 0;
    li {
      width: 100%;
      padding: 10px 25px;
      text-align: center;
      position: relative;
      margin: 0;
      text-transform: capitalize;
      cursor: pointer;
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        height: 1px;
        background: rgb(206, 204, 204);
      }
      &:last-of-type {
        &::after {
          display: none;
        }
      }
    }
  }
}
.btnTran {
  background: transparent !important;
  box-shadow: none;
}
.menuLink {
  cursor: pointer;
}
.mask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>