<template>
  <div class="marginTopAdd">
    <ul class="pl-0 mt-5 priceExpBox">
      <li class="mx-0">
        <p class="mb-1">
          <b>Rs. {{ numberFormat(gig.offerAmountFrom) }}</b>
          <b v-if="gig.offerAmountTo">
            - Rs. {{ numberFormat(gig.offerAmountTo) }}</b
          >
        </p>
      </li>
      <li>
        <p>{{ getHourTime(gig.posted) }}s ago</p>
      </li>
    </ul>
    <p class="px-1">
      {{ gig.description }}
    </p>
    <div class="mb-2 px-1 skills" v-if="gig.skills.length > 0">
      <h2 class="mt-3 titleBox">Skills Required</h2>
      <span
        class="tab"
        v-for="(dat, index) in gig.skills"
        :key="index + 'skill'"
      >
        {{ dat }}</span
      >
    </div>
    <div class="mb-5 px-1" v-if="gig.product">
      <h2 class="mt-3 titleBox">Product Detail</h2>
      <ul class="detailBox">
        <li>
          <img :src="gig.product.medias[0].mediaLink" alt="" />
          <strong>{{ gig.product.productName }}</strong>
        </li>
        <li class="mt-5">
          <p v-html="gig.product.description"></p>
        </li>
      </ul>
    </div>
    <div class="mb-5 px-1">
      <h2 class="mt-3 titleBox">GIG Activity</h2>
      <ul class="detailBox">
        <li>
          <dl>
            <dt>Total Application:</dt>
            <dd>{{ gig.gigApplied }}</dd>
          </dl>
        </li>
        <li class="mt-5">
          <dl>
            <dt>Total Images Required:</dt>
            <dd>{{ gig.numberofimage }}</dd>
          </dl>
        </li>
        <li class="mt-5">
          <dl>
            <dt>Total Video Required:</dt>
            <dd>{{ gig.numberofvideo }}</dd>
          </dl>
        </li>
        <li class="mt-5">
          <dl>
            <dt>Approved:</dt>
            <dd>{{ gig.gigApproved }} / {{ gig.numberofcreator }}</dd>
          </dl>
        </li>
        <li class="mt-5">
          <dl>
            <dt>Posting Required:</dt>
            <dd>{{ gig.requirePosting ? "Yes" : "No" }}</dd>
          </dl>
        </li>
       
        <li
          class="mt-5"
          v-if="
            gig.gigAppliedDetail != undefined &&
            gig.gigAppliedDetail.submissionDate != undefined
          "
        >
          <dl v-if="dateDiff >= 1">
            <dt>Expires On:</dt>
            <dd v-if="timerValue">
              {{ timerValue.days }} d : {{ timerValue.hours }} h :
              {{ timerValue.minutes }} m : {{ timerValue.seconds }} s
            </dd>
          </dl>
          <dl v-if="dateDiff < 1">
            <dt>Expires On:</dt>
            <dd>Expired</dd>
          </dl>
        </li>
      </ul>
    </div>
    <div class="mb-5 px-1">
      <h2 class="mt-3 titleBox">
        <ul class="detailBox mt-0 company">
          <li>
            <img :src="gig.business.profileFile" alt="" />
            <div class="d-flex flex-column">
              <strong>{{ gig.business.businessName }}</strong>
              <small>Seller Verified</small>
            </div>
          </li>
        </ul>
      </h2>
      <ul class="companyDetail">
        <li>
          <dl>
            <dt>
              <img src="@/assets/images/icon/paymentVerified.svg" alt="" />
            </dt>
            <dd>Payment Verified</dd>
          </dl>
        </li>
        <li>
          <dl>
            <dt>
              <img src="@/assets/images/icon/user.svg" />
            </dt>
            <dd>
              <v-rating
                :value="0"
                dense
                color="#FFBE5B"
                readonly
                background-color="#FFBE5B"
              ></v-rating>
              <strong class="ml-2">0</strong>
              <small>(0 Reviews)</small>
            </dd>
          </dl>
        </li>
        <li>
          <dl>
            <dt>
              <img src="@/assets/images/icon/mapmarker.svg" />
            </dt>
            <dd>{{gig.business.address}}</dd>
          </dl>
        </li>
        <li>
          <dl>
            <dt>
              <img src="@/assets/images/icon/clock.svg" />
            </dt>
            <dd>
              Member since {{ gig.business.createdAt | moment("MMM DD, YYYY") }}
            </dd>
          </dl>
        </li>
      </ul>
    </div>

    <ul class="buttonList" v-if="gig.gigAppliedDetail == null">
      <li><v-btn @click="applyForGig">Apply Now</v-btn></li>
    </ul>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "GigDetail",
  data: () => ({
    applyText: "Apply For the Gig",
    apply: true,
    postName: undefined,
    description: "",
    uploadSuccess: false,
    gig: undefined,
    loading: true,
    menuBox: "details",
    timerValue: undefined,
    dateDiff: undefined,
  }),
  methods: {
    ...mapActions(["getGig", "postGigApply", "postGigContentThread"]),
    numberFormat(givenNumber) {
      let internationalNumberFormat = new Intl.NumberFormat("en-US");
      return internationalNumberFormat.format(givenNumber);
    },
    acceptAct() {
      this.apply = !this.apply;
    },
    applyAct() {
      this.applyText = "Please submit your propsal";
      this.apply = false;
    },
    async applyForGig() {
      let data = {};
      if (this.description) {
        data.description = this.description;
      }
      data.id = this.$route.params.id;
      await this.postGigApply(data);
      this.$router.push("/gigApplied");
    },
    getHourTime(date, reverse) {
      let now;
      let expiration;
      if (reverse) {
        now = moment(new Date());
        expiration = moment(date);
      } else {
        expiration = moment(new Date());
        now = moment(date);
      }
      const diff = expiration.diff(now);
      const diffDuration = moment.duration(diff);
      if (reverse) {
        return {
          days: diffDuration.days(),
          hours: diffDuration.hours(),
          minutes: diffDuration.minutes(),
          seconds: diffDuration.seconds(),
        };
      } else {
        if (diffDuration.days() != 0) {
          return diffDuration.days() + " day";
        }
        if (diffDuration.hours() != 0) {
          return diffDuration.hours() + " hour";
        }
        if (diffDuration.minutes != 0) {
          return diffDuration.minutes() + " minute";
        }
      }
    },
    getTimerBox(date, reverse) {
      setInterval(() => {
        this.timerValue = this.getHourTime(date, reverse);
      }, 1000);
    },
  },
  computed: {
    ...mapGetters(["allGig"]),
  },
  async created() {
    this.gig = this.allGig.results;
    if (this.gig.gigAppliedDetail  != undefined && this.gig.gigAppliedDetail.submissionDate != undefined) {
      this.getTimerBox(this.gig.gigAppliedDetail.submissionDate, true);
      var a = moment(new Date(this.gig.gigAppliedDetail.submissionDate));
      var b = moment(new Date());
      this.dateDiff = a.diff(b, "days"); // 1
    }
  },
};
</script>


<style lang="scss" scoped>
.marginTopAdd {
  margin-top: 140px;
  &::-webkit-scrollbar {
    display: none;
  }
}
.titleBox {
  border: 1px solid #dcdcdc;
  border-left: none;
  border-right: none;
  width: 106%;
  margin: 0 -15px 10px;
  padding: 15px 20px;
  small {
    font-weight: 300;
    font-size: 12px;
  }
}
.detailBox {
  margin-left: 0;
  padding-left: 0;
  margin-top: 20px;
  img {
    width: 50px;
    height: 50px;
    border: 1px solid #dcdcdc;
    object-fit: cover;
    margin-right: 15px;
    border-radius: 3px;
  }

  li {
    display: flex;
    align-items: center;
    margin-left: 0;
    dl {
      width: 100%;
      display: flex;
      dt,
      dd {
        width: 50%;
      }
      dd {
        font-weight: 500;
      }
    }
    strong {
      font-size: 16px;
    }
  }
}
.companyDetail {
  padding-left: 0;
  display: flex;
  flex-flow: column;
  li {
    margin-left: 0;
    margin-top: 15px;
    dl {
      display: flex;
      dt {
        margin-right: 15px;
      }
      dd {
        display: flex;
        align-items: center;
        small {
          margin-left: 10px;
        }
      }
    }
  }
}
.buttonList {
  padding-left: 0;
  margin: 0 0 40px;
  width: 100%;
  li {
    margin: 0 !important;
    width: 100%;
    .v-btn {
      width: 100%;
      min-height: 50px;
      background: #725ee3;
      color: #fff;
    }
  }
}
.btnWidth {
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 5px;
    width: 250px !important;
    min-height: 50px;
  }
  .waitingBtn {
    background: orange !important;
  }
  .rejectBtn {
    background: red !important;
  }
  .approveBtn {
    background: green !important;
  }
}
h2 {
  font-weight: 500;
  margin-top: 20px;
  font-size: 20px;
}
p {
  margin-bottom: 15px !important;
}
header {
  box-shadow: 0 3px 5px #efefef;
  padding: 15px 0 10px;
  margin: 0;
  img {
    width: 160px;
  }
}
.marginrow {
  margin: -15px -15px 0;
}
.tab {
  padding: 5px 15px;
  font-size: 12px;
  border-radius: 30px;
  margin-top: 10px;
  margin-bottom: 20px;
  display: inline-block;
  background: #bcbcbc40;
  color: #444444;
}
.files {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  .post {
    margin: 3px;
    width: 23%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid #efefef;
    img {
      padding: 10px 0;
      width: 100%;
    }
  }
}
.priceExpBox {
  display: flex;
  justify-content: space-between;
  li {
    p {
      &:first-of-type {
        font-weight: 500;
      }
    }
  }
}
.infoProposal {
  width: 100%;
  li {
    display: flex;
    padding: 10px 0;
    justify-content: space-between;
  }
}
.loadingBox {
  display: flex;
  align-items: center;
  flex-flow: column;
  justify-content: center;
  padding: 100px 0;
}
.skills {
  .tab {
    text-transform: capitalize;
    margin-right: 10px;
  }
}
.company {
  img {
    object-fit: contain;
  }
}
</style>